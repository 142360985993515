/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useMutation } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { cloneDeep } from 'lodash'
import React from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router'

import { emailInHistory, trackChanges } from '../../components/feature-flags'
import FormbotContainer from '../../components/formbot-container/formbot-container'
import MoreOptionsMenu from '../../components/formbot-container/more-options-menu'
import { PortalOrange } from '../../components/portals'
import { useTenantFeaturesContext } from '../../components/tenant-features-context'
import { AppIdsProvider } from '../../components/use-app-ids-context'
import { useDocumentTitle } from '../../components/use-document-title'
import { useQuery } from '../../components/use-query'
import { useWindowWidth } from '../../components/use-window-width'
import VersionsDropdown, {
  DocumentStatus
} from '../../components/versions-dropdown'
import Formbot from '../../formbot'
import * as Icons from '../../icons'
import { isMyDraft } from '../../pages-builder/document-list/components/row'
import NotFound from '../../pages/not-found'
import { useAlerts } from '../../ui/alerts'
import { Popover2 } from '../../ui/popover'
import WorkflowTracker from '../action/workflow-tracker'
import * as DocumentHistory from '../document-history'
import * as CompareChanges from './components/compare-changes'
import Options from './components/export-options-pdf'

export default function ViewForm () {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { documentId, actionId, versionId } = useParams()
  const navigate = useNavigate()
  const workingDocumentId = versionId ?? documentId
  const q = getViewFormQuery(workingDocumentId, actionId)
  const { data, loading, error } = useQuery(q)
  const [newVersionLoading, setNewVersionLoading] = React.useState(false)
  useDocumentTitle(data?.document?.app?.name)
  const notFound = !loading && !data?.document

  const showDocumentForPrint = !!searchParams.get('showDocument')
  const showWorkflowForPrint = !!searchParams.get('showWorkflow')
  const showCommentsForPrint = !!searchParams.get('showComments')
  const isPrintMode =
    showDocumentForPrint || showWorkflowForPrint || showCommentsForPrint

  const [showingHistory, setShowingHistory] = React.useState(
    !!searchParams.get('showWf')
  )
  const [animateFormbot, setAnimateFormbot] = React.useState(false)

  const tenantFeatures = useTenantFeaturesContext()
  const tenantVersionSettings = tenantFeatures?.versions ?? false
  const removeDocument = useRemoveDocumentMutation(documentId)
  const alerts = useAlerts()
  const width = useWindowWidth()
  const handleDeleteClick = async () => {
    const deleteButton = close => {
      return (
        <button
          className='kp-button-solid'
          onClick={async () => {
            close()
            versionId ? navigate('../../../.') : navigate('../../.')
            await removeDocument().catch(() => {
              alerts.type3(
                i18n._('pagesrunner.viewform.failed.delete'),
                'error'
              )
            })
          }}
          data-testid='confirm-delete'
        >
          <Trans id='delete' />
        </button>
      )
    }
    alerts.type1(
      i18n._('pagesrunner.viewform.permanent'),
      close => (
        <DeleteWarningContent close={close} documentId={workingDocumentId} />
      ),
      'confirm',
      deleteButton
    )
  }
  const structure = React.useMemo(
    () => ({
      template: cloneDeep(data?.document?.form?.template),
      metaFields: data?.document?.form?.metaFields ?? [],
      integrationFields: data?.document?.form?.integrationFields ?? [],
      trashed: []
    }),
    [data]
  )
  const appId = data?.document?.app?.id
  const document = {
    data: data?.document?.data,
    meta: data?.document?.meta || {},
    integration: data?.document?.integration || {}
  }
  const [compareState, compareContext] = CompareChanges.useCompareState(
    getViewFormQuery,
    workingDocumentId,
    data,
    document,
    structure
  )
  const canEdit = data?.document?.viewer?.canEdit
  const canDelete = data?.document?.viewer?.canDelete && !versionId
  const canExport = data?.document?.dataset?.allowExport
  if (error || notFound) return <NotFound />
  const hasWFTroubleshootPerms = data?.document?.app?.hasWFTroubleshootPerms
  const history = data?.document?.history
  const simulation = data?.document?.workflow?.simulation
  const workflowResends = data?.document?.meta?.workflowResends
  const hasSimulationSteps = (simulation?.steps || []).length > 0

  const shouldSeeActionButtons = location.pathname.includes('/document-list')
  const versionsEnabled =
    data?.document?.dataset?.allowNewVersions && tenantVersionSettings
  const hasVersions = versionsEnabled && data?.document?.versions?.length > 1
  const showVersionDropdown =
    versionsEnabled &&
    (data?.document?.viewer?.canCreateVersion ||
      data?.document?.versions?.length > 1)

  const titleValue = data?.document?.meta?.title

  const modalHeader = (
    <PortalOrange>
      <div className='flex flex-1 items-center max-md:flex-wrap'>
        <h1
          title={titleValue}
          className='mr-6 max-w-64 truncate text-base font-medium dark:text-white max-md:pb-2'
          data-testid='doc-title'
        >
          {titleValue}
        </h1>
        {showVersionDropdown ? (
          <VersionsDropdown
            document={data?.document}
            onNewVersionLoading={setNewVersionLoading}
            routeIdentifierCallback={version =>
              getVersionPath(version, documentId, versionId, location)
            }
          />
        ) : (
          <DocumentStatus
            meta={data?.document?.meta}
            simulation={data?.document?.workflow?.simulation}
          />
        )}
      </div>
      {(width > 1024 || !showingHistory) && (
        <div className='small-top-shadow mr-4 flex flex-row items-center gap-2 max-lg:fixed max-lg:bottom-0 max-lg:left-0 max-lg:w-full max-lg:justify-center max-lg:bg-white max-lg:p-4 print:hidden'>
          {hasSimulationSteps && shouldSeeActionButtons && emailInHistory && (
            <DocumentHistory.Button
              onClick={() => {
                if (!showingHistory) {
                  setAnimateFormbot(true)
                }
                setShowingHistory(!showingHistory)
              }}
            />
          )}
          {canEdit && shouldSeeActionButtons && (
            <>
              <Link to='../edit' className='kp-button-solid max-lg:w-full'>
                <Trans id='pagesrunner.viewform.edit' />
              </Link>
            </>
          )}
          {(canExport || canDelete || hasSimulationSteps) &&
            (shouldSeeActionButtons || (trackChanges && hasVersions)) && (
              <MoreOptionsMenu
                additionalButtons={hide => (
                  <>
                    {canExport && shouldSeeActionButtons && (
                      <Popover2
                        role='sub-menu'
                        className='-order-1 md:order-1'
                        trigger={
                          <button className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'>
                            <Icons.ExportAll />
                            <Trans id='pagesrunner.viewform.export' />
                          </button>
                        }
                        right={96}
                        top={width >= 1024 && -4}
                        bottom={width < 1024 && -4}
                      >
                        {() => (
                          <Options
                            documentId={workingDocumentId}
                            onCancel={hide}
                          />
                        )}
                      </Popover2>
                    )}
                    {trackChanges && hasVersions && !compareState.id && (
                      <Link
                        to={`?compare=${compareState.previousVersionId}`}
                        onClick={hide}
                        className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                      >
                        <Icons.Visible />
                        <Trans
                          id='pagesrunner.viewform.show_changes'
                          message='Show Changes'
                        />
                      </Link>
                    )}
                    {!!compareState.id && (
                      <Link
                        to={location.pathname.includes('actions') ? '..' : ''}
                        onClick={hide}
                        className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                      >
                        <Icons.Hidden />
                        <Trans
                          id='pagesrunner.viewform.hide_changes'
                          message='Hide Changes'
                        />
                      </Link>
                    )}
                    {canDelete && shouldSeeActionButtons && (
                      <button
                        className='flex w-full cursor-pointer items-center gap-2 whitespace-nowrap px-4 py-2 text-sm hover:bg-light-gray-200 dark:hover:bg-light-gray-100'
                        data-testid='delete-button'
                        onClick={() => {
                          handleDeleteClick()
                          hide()
                        }}
                      >
                        <Icons.Delete />
                        <span>
                          <Trans id='delete' />
                        </span>
                      </button>
                    )}
                  </>
                )}
                onClick={() => {
                  if (!showingHistory) {
                    setAnimateFormbot(true)
                  }
                  setShowingHistory(!showingHistory)
                }}
                shouldShowHistory={
                  hasSimulationSteps &&
                  shouldSeeActionButtons &&
                  !emailInHistory
                }
                width={width}
              />
            )}
        </div>
      )}
    </PortalOrange>
  )

  const displayDocument =
    showDocumentForPrint ||
    (!isPrintMode && (!compareState.id || !compareState.onlyChanges))

  const formbotProps = {
    animate: animateFormbot,
    hideSidebars: showingHistory
  }

  if (displayDocument) {
    formbotProps.loading = loading || compareState.loading || newVersionLoading
  }

  return (
    <>
      {modalHeader}
      <div className='flex'>
        <div className='flex-grow'>
          <FormbotContainer
            {...formbotProps}
            header={
              <CompareChanges.Header
                compareState={compareState}
                cb={v =>
                  getVersionPath(v, documentId, versionId, location, true)
                }
              />
            }
            formbot={
              <div className='print:background' data-formbot>
                {displayDocument && (
                  <AppIdsProvider appId={appId}>
                    <Formbot.View
                      className='formbot formbot-view'
                      document={document}
                      structure={structure}
                      branding={data?.document?.app?.branding}
                      context={{
                        compare: compareContext,
                        documentId: workingDocumentId,
                        documentMeta: document.meta,
                        labelSize: data?.document?.dataset?.labelSize
                      }}
                    />
                    <CompareChanges.Deletions compareState={compareState} />
                  </AppIdsProvider>
                )}
                {showWorkflowForPrint && (
                  <>
                    {hasSimulationSteps && (
                      <WorkflowTracker
                        simulation={simulation}
                        hideComments={!showingHistory && !showCommentsForPrint}
                      />
                    )}
                  </>
                )}
                <CompareChanges.Changes compareState={compareState} />
              </div>
            }
            width={width}
          />
        </div>
        <DocumentHistory.View
          documentHistory={{
            appId,
            dataSetId: data?.document?.dataset?.id,
            documentId: workingDocumentId,
            hasWFTroubleshootPerms,
            hasVersions: showVersionDropdown,
            isTable: data?.document?.dataset?.isTable,
            firstPageId: data?.document?.app?.firstPageId,
            history,
            simulation,
            versions:
              data?.document?.versions?.length > 1
                ? data?.document?.versions
                : [],
            workflowResends
          }}
          documentQuery={q}
          hide={() => {
            setAnimateFormbot(true)
            setShowingHistory(false)
          }}
          isVisible={!newVersionLoading && showingHistory}
          width={width}
        />
      </div>
    </>
  )
}

const versionedDocumentQuery = gql`
  query versionedDocument($documentId: ID!, $actionId: ID!) {
    document: versionedDocument(documentId: $documentId, actionId: $actionId) {
      id
      data
      documentSetId
      integration
      status
      workflow {
        simulation
      }
      viewer {
        canEdit
        canDelete
        canCreateVersion
      }
      meta
      form: versionedForm {
        id
        template
        metaFields {
          id
          formKey
          type
          label
          details
        }
        integrationFields {
          id
          formKey
          type
          label
          details
        }
      }
      app {
        id
        name
        hasWFTroubleshootPerms: hasWfTroubleshootPerms
        firstPageId
        branding {
          id
          color
          logo
          maxHeight
          alt
        }
      }
      dataset {
        id
        labelSize
        allowExport
        isTable
        allowNewVersions
      }
      versions {
        id
        meta
        viewer {
          actions {
            id
            type
            details
          }
        }
        workflow {
          simulation
        }
      }
      hasIncompleteVersion
    }
  }
`

const getDocument = gql`
  query getDocument($documentId: ID!) {
    document(id: $documentId, keyBy: ID) {
      id
      data
      documentSetId
      integration
      status
      history {
        content
        date
        id
        type
      }
      workflow {
        simulation
      }
      viewer {
        canEdit
        canDelete
        canCreateVersion
      }
      meta
      form {
        id
        template
        metaFields {
          id
          formKey
          type
          label
          details
        }
        integrationFields {
          id
          formKey
          type
          label
          details
        }
      }
      app {
        id
        name
        hasWFTroubleshootPerms: hasWfTroubleshootPerms
        firstPageId
        branding {
          id
          color
          logo
          maxHeight
          alt
        }
      }
      dataset {
        id
        labelSize
        allowExport
        isTable
        allowNewVersions
      }
      versions {
        history {
          content
          date
          id
          type
        }
        id
        meta
        viewer {
          actions {
            id
            type
            details
          }
        }
        workflow {
          simulation
        }
      }
      hasIncompleteVersion
    }
  }
`
const getViewFormQuery = (documentId, actionId, rest = {}) => ({
  variables: actionId ? { documentId, actionId } : { documentId },
  fetchPolicy: 'cache-and-network',
  query: actionId ? versionedDocumentQuery : getDocument,
  ...rest
})

const useRemoveDocumentMutation = id => {
  const [removeDocument] = useMutation(mutation)
  return () => removeDocument(getParams(id))
}

const mutation = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`

const getParams = id => ({
  variables: { id },
  refetchQueries: ['ListPageQuery']
})

const dependentDocumentsQuery = gql`
  query getDependentDocuments($documentId: ID!, $limit: Int, $skip: Int) {
    document(id: $documentId) {
      id
      dependentDocumentConnection(args: { limit: $limit, skip: $skip }) {
        totalCount
        edges {
          node {
            id
            label
            appId
            datasetId
          }
        }
      }
    }
  }
`

function DeleteWarningContent ({ close, documentId }) {
  const [displayCount, setDisplayCount] = React.useState(5)
  const { data, error, loading, fetchMore } = useQuery({
    query: dependentDocumentsQuery,
    variables: { documentId, limit: 5, skip: 0 }
  })
  const totalCount = data?.document?.dependentDocumentConnection?.totalCount

  if (loading || error || !totalCount) {
    return <Trans id='pagesrunner.viewform.permanent.data' />
  }

  const edges = data.document.dependentDocumentConnection.edges || []

  const additional = totalCount - displayCount

  const handleClickLink = e => {
    if (!(e.ctrlKey || e.metaKey)) {
      close()
    }
  }

  const handleClickMore = () => {
    if (edges.length > displayCount) {
      setDisplayCount(edges.length)
    } else {
      fetchMore({ variables: { limit: 100, skip: edges.length } }).then(
        result => {
          setDisplayCount(
            count =>
              count +
              result.data.document.dependentDocumentConnection.edges.length
          )
        }
      )
    }
  }

  const handleClickLess = () => {
    setDisplayCount(5)
  }

  return (
    <>
      <p>
        <Trans
          id='pagesrunner.viewform.permanent.dependents'
          message='This document’s data is currently used in the following documents:'
        />
      </p>
      <div className='max-h-[70svh] overflow-y-auto'>
        <ul className='list-inside list-disc pl-2 text-text-link'>
          {edges.slice(0, displayCount).map(({ node }) => (
            <li key={node.id}>
              <Link
                to={generateDependentUrl(node)}
                onClick={handleClickLink}
                className='underline'
              >
                {node.label}
              </Link>
            </li>
          ))}
        </ul>
        {additional > 0 ? (
          <button
            onClick={handleClickMore}
            className='text-text-link underline'
          >
            <Trans
              id='pagesrunner.viewform.permanent.more'
              message='See {count} additional {count, plural, =1 {document} other {documents}}'
              values={{ count: additional }}
            />
          </button>
        ) : displayCount > 5 ? (
          <button
            onClick={handleClickLess}
            className='text-text-link underline'
          >
            <Trans
              id='pagesrunner.viewform.permanent.less'
              message='See less'
            />
          </button>
        ) : null}
      </div>
    </>
  )
}

function generateDependentUrl (node) {
  const parts = []
  if (node.datasetId && node.datasetId !== node.appId) {
    parts.push('app', node.appId, 'page', node.datasetId, 'document-list')
  } else {
    parts.push('document-list', node.appId)
  }
  parts.push(node.id, 'view')
  return '/' + parts.join('/')
}

function getVersionPath (
  version,
  documentId,
  versionId,
  location,
  preferVersionsPage
) {
  const id = version?.id
  const draftId = isMyDraft(version)
  const initialActionId = version?.workflow?.simulation?.steps?.[0]?.actionId
  const hasActions = version?.viewer?.actions?.length
  const onActionRoute = location.pathname.includes('actions')
  const onVersionRoute = location.pathname.includes('versions')
  const workflowStatus = version?.meta?.workflowStatus

  if (onActionRoute && onVersionRoute) {
    return workflowStatus === 'In Progress' && !preferVersionsPage
      ? '..'
      : `../versions/${id}/view`
  }
  if (id === documentId && !versionId) {
    return '../view'
  }
  if (id === documentId && onVersionRoute && !hasActions) {
    return '../../view'
  }
  if (id === documentId && onVersionRoute && hasActions) {
    return draftId ? `../../../${draftId}/action` : '../../view'
  }
  if (id === versionId && onVersionRoute) {
    return '.'
  }
  if (workflowStatus === 'Draft' && hasActions) {
    return `../../${initialActionId}/action`
  }
  if (onVersionRoute) {
    return `../../versions/${id}/view`
  }
  return `../versions/${id}/view`
}
