/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql, useQuery } from '@apollo/client'
import { Trans } from '@lingui/react'
import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { Tab, TabInner, Tabs } from '../../ui/tabs'

export default function IntegrationDetails () {
  const location = useLocation()
  const advanced = location?.pathname?.includes('advanced')
  const { integrationId } = useParams()
  const { data, loading, error } = useQuery(integrationQuery, {
    variables: { integrationId }
  })

  return (
    <ModalPage
      className='text-sm'
      title={
        advanced ? (
          <div />
        ) : (
          <Title>
            <StyledSpan className='text-dark-gray-500'>
              {data?.integration?.data?.__name}
            </StyledSpan>
          </Title>
        )
      }
      nav={<Nav advanced={advanced} />}
    >
      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : (
        <Outlet
          context={{
            id: data?.integration?.data?.trueId,
            integration: data?.integration,
            integrationTemplate: data?.integrationTemplate
          }}
        />
      )}
    </ModalPage>
  )
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
`

const StyledSpan = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: 500;
`

function Nav ({ advanced }) {
  return (
    <Tabs>
      <Tab to='edit'>
        <TabInner>
          <Trans id='details' />
        </TabInner>
      </Tab>
      <Tab to='sharing'>
        <TabInner>
          <Trans id='data.sharing' />
        </TabInner>
      </Tab>
      {advanced ? null : (
        <Tab to='used-by'>
          <TabInner>
            <Trans id='apps' />
          </TabInner>
        </Tab>
      )}
    </Tabs>
  )
}

const integrationQuery = gql`
  query Integration($integrationId: ID!) {
    integration(id: $integrationId) {
      id
      sharedWithOthers {
        type
        apps {
          id
          name
          type
          tileOptions {
            backgroundColor
            iconName
          }
        }
      }
      data
      filledSecrets
      invokeUrl
      appsUsing {
        id
        name
        type
        tileOptions {
          backgroundColor
          iconName
        }
        createdBy {
          id
          displayName
        }
        createdAt
        viewer {
          canViewDocuments
          canManage
          canSubmitDocuments
        }
        firstPageId
      }
    }
    integrationTemplate {
      id
      template
    }
  }
`
