/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { filter, get, includes, map, uniq, without } from 'lodash'
import React from 'react'

import Checkbox from '../../../ui/checkbox'
import { STATUSES, STATUS_LABEL_MAP } from './consts'

const StatusCheckbox = ({ status, values, onChange }) => {
  const checked = React.useMemo(
    () => includes(values, status.value),
    [values, status]
  )
  const handleChange = React.useCallback(() => {
    const newValues = checked
      ? without(values, status.value, ...(status.additionalValues || []))
      : values.concat(status.value, ...(status.additionalValues || []))
    onChange(newValues)
  }, [checked, values, status, onChange])
  return (
    <Checkbox checked={checked} label={status.label} onChange={handleChange} />
  )
}

export const UI = ({ gadget, onChange, value }) => {
  const values = get(value, 'values') || []
  const ignoredStatuses = gadget.filterOut || []
  const handleStatusChange = React.useCallback(
    values => {
      const filter = value || {
        field: gadget.formKey,
        type: 'WorkflowStatus',
        values: []
      }
      filter.values = values
      onChange(filter.values.length ? filter : null)
    },
    [value, onChange, gadget]
  )
  const validStatuses = filter(
    STATUSES,
    v => !ignoredStatuses.includes(v.class)
  )
  return (
    <div>
      <div>Filter</div>
      {map(validStatuses, status => (
        <StatusCheckbox
          key={status.value}
          status={status}
          values={values}
          onChange={handleStatusChange}
        />
      ))}
    </div>
  )
}

export const Pill = ({ label, filter, gadget }) => {
  const values = get(filter, 'values') || []
  const str = React.useMemo(() => {
    return uniq(map(values, entry => STATUS_LABEL_MAP[entry])).join(', ')
  }, [values])
  return (
    <span>
      {label}: {str}
    </span>
  )
}

export const toGraphQL = myFilter =>
  map(myFilter.values, value => ({
    field: `${myFilter.field}`,
    type: 'IS',
    value
  }))

export const fromGraphQL = gqlFilters => ({
  field: gqlFilters[0].field,
  type: 'WorkflowStatus',
  values: map(gqlFilters, 'value')
})

export const options = () => [
  {
    id: 'IS_SELECTED',
    label: i18n._('is'),
    exposes: 'multi-select',
    options: STATUSES.map(o => ({ id: o.value, label: o.label }))
  },
  {
    id: 'IS_NOT_SELECTED',
    label: i18n._({ id: 'is.not', message: 'is not' }),
    exposes: 'multi-select',
    options: STATUSES.map(o => ({ id: o.value, label: o.label }))
  }
]
