/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { find, get } from 'lodash'
import React from 'react'
import { Link } from 'react-router'

import ViewModal from '../table/view-modal'

export default function View ({ value, fbRenderView, condensed }) {
  const [showModal, setShowModal] = React.useState(false)
  if (!value) return ''
  if (condensed) {
    return (
      <div className='flex items-center justify-center'>
        {value?.children?.length > 0 && (
          <button
            onClick={event => {
              event.stopPropagation(true)
              event.preventDefault()
              setShowModal(true)
            }}
            className='kp-button-transparent'
            aria-label={i18n._({ id: 'view.rules', message: 'View Rules' })}
          >
            <Trans id='view.rules' message='View Rules' />
          </button>
        )}
        <ViewModal
          setShowModal={setShowModal}
          showModal={showModal}
          title='View Rules'
        >
          <RulesView
            value={value}
            fbRenderView={fbRenderView}
            className='w-full rounded bg-white p-2'
          />
        </ViewModal>
      </div>
    )
  }
  return <RulesView value={value} fbRenderView={fbRenderView} />
}

const RulesView = ({ value, fbRenderView, className }) => {
  if (!value) return ''
  return (
    <div className={className}>
      {value.logic && value.children.length > 1 && (
        <p className='p-2 font-medium'>
          <Trans
            id='complete.logic.of.following'
            values={{ logic: value.logic }}
          />
        </p>
      )}
      {value.children.map((child, index) => {
        const Component = child.type === 'ruleGroup' ? RuleGroup : Rule
        return (
          <Component
            key={index}
            item={child}
            data={
              child.type === 'ruleGroup'
                ? value.data
                : find(value.data, { id: child.id })
            }
            fbRenderView={fbRenderView}
          />
        )
      })}
    </div>
  )
}

const RuleGroup = ({ item, data, fbRenderView }) => {
  return (
    <div className='m-4 border border-light-gray-300'>
      <p className='flex h-12 w-full items-center justify-between bg-light-gray-200 px-4'>
        {item.label}
      </p>
      {item.logic && item.children.length > 1 && (
        <p className='p-2 font-medium'>
          <Trans
            id='complete.logic.of.following'
            values={{ logic: item.logic }}
          />
        </p>
      )}
      <div>
        {item.children.map((child, index) => (
          <RulesView
            key={index}
            value={{ children: [child], data }}
            fbRenderView={fbRenderView}
          />
        ))}
      </div>
    </div>
  )
}

const Rule = ({ item, data }) => {
  const groupedValues = groupValues(item.value?.value, data?.data)
  return (
    <div className='flex flex-col gap-1 px-4 py-2'>
      {groupedValues.map((rulePart, i) => (
        <div key={i}>{rulePart}</div>
      ))}
    </div>
  )
}

function groupValues (values, data) {
  return values?.reduce((acc, part) => getPart(part, data, acc), [[]])
}

function getPart (part, data, acc) {
  const value = part.type === 'Spacer' ? part.ruleText : get(data, part.formKey)
  if (!value) return acc
  if (['Spacer', 'Number', 'Text'].includes(part.type)) {
    acc[acc.length - 1].push(` ${value} `)
  }
  if (part.type === 'FormTypeahead') {
    acc[acc.length - 1].push(
      <DataLink key={part.id} part={part} item={value} />
    )
  }
  if (part.type === 'FormMultiselect') {
    acc.push([<RuleList key={part.id} part={part} multiSelectData={value} />])
  }
  return acc
}

const RuleList = ({ part, multiSelectData }) => {
  return (
    <ul className='ml-10 flex flex-col gap-1'>
      {multiSelectData.map(item => {
        return (
          <li key={item.id} className='list-disc text-text-link'>
            <DataLink part={part} item={item} />
          </li>
        )
      })}
    </ul>
  )
}

const DataLink = ({ part, item }) => {
  const url = getUrl(part, item)
  return (
    <Link
      key={item.id}
      aria-label={item.label}
      to={url}
      title={'Open ' + item.label}
      target='_blank'
      className='text-text-link hover:underline'
    >
      {item.label}
      {item.data?.kuali_credits?.credits && (
        <span> ({item.data.kuali_credits.credits})</span>
      )}
    </Link>
  )
}

const getUrl = (part, item) => {
  return part.details?.isProduct
    ? `/app/${part.details.id}/page/${part.details.pageId}/document-list/${item.id}/view`
    : `/document-list/${part.details.id}/${item.id}/view`
}
