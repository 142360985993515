/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { cloneDeep, filter, keyBy, mapValues, some } from 'lodash'
import React from 'react'
import shortid from 'shortid'
import styled from 'styled-components'

import sanitize from '../../../components/sanitize'
import Tooltip, { TooltipTrigger } from '../../../components/tooltip'
import { AlertHelp } from '../../../icons'
import * as fb from '../../engine/formbot/utils'

export const newRow = (gadgets, formbot, isFooter) => ({
  ...mapValues(keyBy(filter(gadgets, 'formKey'), 'formKey'), ({ type }) =>
    cloneDeep(formbot.getGadget(type).defaultValue ?? null)
  ),
  _rowId: shortid.generate(),
  _isFooter: isFooter
})

export function removeRowBasedProgDisc (g) {
  const { conditionalVisibility, ...rest } = g
  const parts = conditionalVisibility?.value?.parts || []
  return parts.find(part => part.formKey.includes('.*.')) ? rest : g
}

export function finalizeRowBasedProgDisc (g, i) {
  const parts = g.conditionalVisibility?.value?.parts || []
  if (!parts.length) return g
  const gg = cloneDeep(g)
  gg.conditionalVisibility.value.parts = parts.map(part => ({
    ...part,
    formKey: part.formKey.replace('.*.', `.${i}.`)
  }))
  return gg
}

export function getCellGadgets (childrenTemplate, formKey, i, formbot) {
  const extraGadgets = childrenTemplate
    .filter(gadget => gadget.formKey)
    .map(g => ({ ...g, formKey: `data.${formKey}.${i}.${g.formKey}` }))
  return fb
    .gatherAllSubGadgets(extraGadgets, formbot)
    .map(g => finalizeRowBasedProgDisc(g, i))
}

export const HiddenGadget = styled.div`
  background: #eee;
  html.dark & {
    // Outlier: dark:bg-light-gray-500
    background: #555;
  }
  height: 100%;
`

export function addExistingValidationGadgets (template, props, formbot) {
  const { value, formKey, progDisc, childrenTemplate } = props
  const ValidationGadgets = childrenTemplate.filter(
    g => g.type === 'Validation'
  )
  if (ValidationGadgets.length === 0) return template

  const showingValGads = value.reduce((acc, row, i) => {
    if (row._isFooter) return acc
    ValidationGadgets.forEach(g => {
      const val = progDisc(
        finalizeRowBasedProgDisc(g, i),
        getCellGadgets(template, formKey, i, formbot).map(ga =>
          finalizeRowBasedProgDisc(ga, i)
        )
      )
      if (!acc[g.id]) acc[g.id] = [val]
      else acc[g.id].push(val)
    })
    return acc
  }, {})
  const showingGadIds = []
  for (const [key, value] of Object.entries(showingValGads)) {
    if (some(value)) showingGadIds.push(key)
  }

  if (!showingGadIds.length) return template

  return childrenTemplate.filter(
    g => showingGadIds.includes(g.id) || template.find(ga => ga.id === g.id)
  )
}

export const Description = ({ description, id, labelSize }) => {
  if (!description?.enabled) return null
  const tooltipId = `gadget-desc-${id}`
  return (
    <TooltipTrigger
      tooltipId={tooltipId}
      className='relative ml-1 inline-block'
    >
      <AlertHelp
        className='fill-blue-500 dark:fill-medium-gray-300'
        label={i18n._('column.information')}
        role='img'
        alt='Column Information'
      />
      <Tooltip id={tooltipId} place='bottom' className='z-10 min-w-max'>
        <DescTooltip
          helpSize={getHelpSize(labelSize)}
          className='ql-editor ql-snow'
          dangerouslySetInnerHTML={{
            __html: sanitize(description.value || '')
          }}
        />
      </Tooltip>
    </TooltipTrigger>
  )
}

function getHelpSize (labelSize) {
  const size = labelSize ?? 'medium'
  if (size === 'huge') return 20
  if (size === 'extra-large') return 18
  if (size === 'large') return 16
  return 14
}

const DescTooltip = styled.p`
  color: white;
  font-size: ${p => p.helpSize}px;
  margin-bottom: 0;
  max-width: 300px;
  white-space: normal;
  padding: 0 !important;
  height: initial;
  min-height: initial !important;
  border: 0;
  & > p {
    color: white;
  }
  & p {
    line-height: 1.42;
    color: #fff;
    font-size: ${p => p.helpSize}px;
  }
  & a {
    color: #9bcceb;
  }
`
