/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'

export const options = () => [
  { id: 'IS', label: i18n._('is'), exposes: 'user-typeahead', transform },
  {
    id: 'IS_NOT',
    label: i18n._({ id: 'is.not', message: 'is not' }),
    exposes: 'user-typeahead',
    transform
  },
  { id: 'IS_EMPTY', label: i18n._({ id: 'is.empty', message: 'is empty' }) },
  {
    id: 'IS_NOT_EMPTY',
    label: i18n._({ id: 'is.not.empty', message: 'is not empty' })
  }
]

function transform (filter, _data) {
  filter.value = filter.value?.id
  return filter
}
