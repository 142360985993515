/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { get, includes, keyBy, map, without } from 'lodash'
import React from 'react'

import Checkbox from '../../../ui/checkbox'

export const UI = ({ gadget, onChange, value }) => {
  const values = get(value, 'values') || []
  const options = get(gadget, 'details.options') || []
  return (
    <div>
      <div>
        <Trans id='filter' />
      </div>
      {map(options, option => (
        <Checkbox
          key={option.key}
          checked={includes(values, option.key)}
          label={option.lbl}
          onChange={() => {
            const newFilter = value || {
              field: gadget.formKey,
              type: 'Checkboxes',
              values: []
            }
            if (includes(newFilter.values, option.key)) {
              newFilter.values = without(newFilter.values, option.key)
            } else {
              newFilter.values.push(option.key)
            }
            onChange(newFilter.values.length ? newFilter : null)
          }}
        />
      ))}
    </div>
  )
}

export const Pill = ({ label, filter, gadget }) => {
  const options = get(gadget, 'details.options') || []
  const optionMap = keyBy(options, 'key')
  const str = map(filter.values, entry => optionMap[entry].lbl).join(', ')
  return (
    <span>
      {label}: {str}
    </span>
  )
}

export const toGraphQL = myFilter =>
  map(myFilter.values, value => ({
    field: `${myFilter.field}.id`,
    type: 'IS',
    value
  }))

export const fromGraphQL = gqlFilters => ({
  field: gqlFilters[0].field.replace('.id', ''),
  type: 'Checkboxes',
  values: map(gqlFilters, 'value')
})

export const options = () => [
  {
    id: 'IS',
    label: i18n._({ id: 'has.checked', message: 'has checked' }),
    exposes: 'single-select'
  },
  {
    id: 'IS_NOT',
    label: i18n._({ id: 'has.not.checked', message: 'has not checked' }),
    exposes: 'single-select'
  },
  // { id: 'NUM_CHECKED', label: 'has checked at least', exposes: 'number' },
  // { id: 'NUM_NOT_CHECKED', label: 'has checked less than', exposes: 'number' },
  { id: 'IS_EMPTY', label: i18n._({ id: 'is.empty', message: 'is empty' }) },
  {
    id: 'IS_NOT_EMPTY',
    label: i18n._({ id: 'is.not.empty', message: 'is not empty' })
  }
]
