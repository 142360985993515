/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'

export const options = () => [
  {
    id: 'IS_SELECTED',
    label: i18n._('includes'),
    exposes: 'group-multiselect',
    transform
  },
  {
    id: 'IS_NOT_SELECTED',
    label: i18n._({ id: 'does.not.include', message: 'does not include' }),
    exposes: 'group-multiselect',
    transform
  },
  { id: 'IS_EMPTY', label: i18n._({ id: 'is.empty', message: 'is empty' }) },
  {
    id: 'IS_NOT_EMPTY',
    label: i18n._({ id: 'is.not.empty', message: 'is not empty' })
  }
]

function transform (filter, _data) {
  if (!filter?.value?.length) return null
  filter.value = JSON.stringify(filter?.value?.map(v => v?.id))
  return filter
}
