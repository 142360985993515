/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
// import { toDollars } from 'calculations/calculation-functions'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { get, groupBy, keyBy, map, pick } from 'lodash'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { AppIcon } from '../components/app-icon'
import AppMultiselect from '../components/app-multiselect'
import { ModalPage } from '../components/modal-page'
import Picker from '../components/picker'
import { DatePicker } from '../components/temporal-pickers'
import Tooltip, { TooltipTrigger } from '../components/tooltip'
import { useTransitionRef } from '../components/use-transition-ref'
import { gadgets } from '../formbot'
import { getDateObject } from '../formbot/gadgets/date-picker/util'
import FormMultiselect from '../formbot/gadgets/form-multiselect/edit'
import FormTypeahead from '../formbot/gadgets/form-typeahead/edit'
import GroupMultiselect from '../formbot/gadgets/group-multiselect/edit'
import GroupTypeahead from '../formbot/gadgets/group-typeahead/edit'
import IntegrationTypeahead from '../formbot/gadgets/integration-typeahead/edit'
import UserMultiselect from '../formbot/gadgets/user-multiselect/edit'
import UserTypeahead from '../formbot/gadgets/user-typeahead/edit'
import * as Icons from '../icons'

export default function FiltersModal ({
  isOpen,
  setIsOpen,
  params,
  updateParams,
  purpose = 'form link',
  compareGadgets,
  hasVersions,
  children
}) {
  const ref = useTransitionRef(isOpen)
  const versionConfigOptions = [
    {
      id: 'LATEST_VERSION',
      label: i18n._('Only.the.latest.version'),
      value: 'LATEST_VERSION'
    },
    {
      id: 'LATEST_SUBMITTED_VERSION',
      label: i18n._('Only.the.latest.submitted.version'),
      value: 'LATEST_SUBMITTED_VERSION'
    },
    {
      id: 'LATEST_COMPLETE_VERSION',
      label: i18n._('Only.the.latest.completed.version'),
      value: 'LATEST_COMPLETE_VERSION'
    }
  ]

  const sharedTermsGadget = params.columns.find(
    c => c.type === 'Terms' && !c.id.includes('.data.')
  )
  const myTermsGadget = compareGadgets?.Terms?.find(
    c => !c?.id?.includes('.data.')
  )

  return (
    <TransitionGroup component={null}>
      <CSSTransition key={isOpen} timeout={450} nodeRef={ref}>
        {isOpen ? (
          <ModalPage
            ref={ref}
            side
            title={i18n._('advanced.settings')}
            onClose={() => setIsOpen(false)}
          >
            <div className='p-4'>
              {myTermsGadget && sharedTermsGadget && (
                <Picker
                  name='needs-version-filter'
                  legend={i18n._('what.can.a.user.search.for.with.this.lookup')}
                  className='pb-6'
                  options={[
                    {
                      label: i18n._({
                        id: 'general.settings',
                        message: 'General settings'
                      }),
                      checked: !params.needsVersionFilter,
                      onChange: () =>
                        updateParams(draft => {
                          draft.needsVersionFilter = false
                        }),
                      optionalLabel: i18n._({
                        id: 'general.settings.description',
                        message:
                          'Configure when documents update and which documents users can search for in data lookups.'
                      })
                    },
                    {
                      label: i18n._({
                        id: 'curriculum.management.settings',
                        message: 'Curriculum Management settings'
                      }),
                      checked: params.needsVersionFilter,
                      onChange: () =>
                        updateParams(draft => {
                          draft.versionFilter = draft.versionFilter || {
                            type: 'AND',
                            operators: [
                              {
                                field: sharedTermsGadget.formKey,
                                type: 'OVERLAPS',
                                value: myTermsGadget.formKey,
                                gadget: 'Terms',
                                isDefault: true
                              }
                            ]
                          }
                          draft.needsVersionFilter = true
                        }),
                      optionalLabel: i18n._({
                        id: 'curriculum.management.settings.description',
                        message:
                          'Documents will update when a new version of the linked document’s terms overlaps with the primary document’s terms and meets filtering criteria. And a user can link to the latest version with additional version criteria. If no overlapping version is found then the latest version will display.'
                      })
                    }
                  ]}
                />
              )}
              {children}
              <div className='flex items-center gap-2 pb-2 text-sm font-medium'>
                <Tooltip id='which-documents-searchable-id' place='right'>
                  <div className='w-48'>
                    <Trans id='data.must.be.made.available.from.data.lookup' />
                  </div>
                </Tooltip>
                <Trans
                  id='what.can.a.user.search.for.with.this.lookup'
                  message='What can a user search for with this lookup?'
                />
                <TooltipTrigger
                  as={Icons.AlertHelp}
                  label={i18n._('what.can.a.user.search.for.with.this.lookup')}
                  tooltipId='which-documents-searchable-id'
                />
              </div>
              {hasVersions && !params.needsVersionFilter && (
                <div className='flex flex-col space-y-1 pb-4'>
                  {versionConfigOptions.map(option => {
                    const optionId = `version-config-${option.id}`
                    return (
                      <label key={optionId} htmlFor={optionId}>
                        <input
                          type='radio'
                          className='kp-radio'
                          id={optionId}
                          name='version-config'
                          value={option.value}
                          checked={params.versionConfig === option.value}
                          onChange={() =>
                            updateParams(draft => {
                              draft.versionConfig = option.value
                            })
                          }
                        />
                        <span>{option.label}</span>
                      </label>
                    )
                  })}
                </div>
              )}
              <FiltersConfig
                params={
                  params.needsVersionFilter
                    ? {
                        filter: params.versionFilter,
                        columns: params.columns
                      }
                    : params
                }
                updateParams={updateParams}
                compareGadgets={compareGadgets}
                purpose={purpose}
                topTitle={
                  params.needsVersionFilter ? (
                    <Trans
                      id="where.the.version's"
                      message="Where the version's"
                    />
                  ) : hasVersions ? (
                    <Trans id="and.where.the.version's" />
                  ) : (
                    <Trans id='Where' />
                  )
                }
                outerKey={
                  params.needsVersionFilter ? 'versionFilter' : 'filter'
                }
              />
            </div>
          </ModalPage>
        ) : (
          <></>
        )}
      </CSSTransition>
    </TransitionGroup>
  )
}

export function FiltersConfig ({
  params,
  updateParams,
  purpose = 'form link',
  compareGadgets,
  topTitle,
  outerKey = 'filter'
}) {
  const modify = build(updateParams, outerKey)
  return (
    <Filters
      filters={params.filter}
      columns={params.columns}
      modify={modify}
      purpose={purpose}
      compareGadgets={compareGadgets}
      topTitle={topTitle}
    />
  )
}

function Filters ({
  filters,
  columns,
  modify,
  purpose,
  compareGadgets,
  topTitle
}) {
  if (!filters) return <Empty modify={modify} purpose={purpose} />
  const visibleFilters = getVisibleFilters(filters)
  if (!visibleFilters) return <Empty modify={modify} purpose={purpose} />
  columns = columns.filter(
    c => !!get(gadgets, [c.type, 'filters', 'options'])?.(purpose)
  )
  const columnsByformKey = keyBy(columns, 'formKey')
  const columnGroups = groupBy(columns, column => {
    if (column.formKey.startsWith('meta.')) return 'Meta Fields'
    if (column.formKey.startsWith('integration.')) return 'Integration Fields'
    return 'Data Fields'
  })
  columnGroups['Nested Logic'] = [
    { formKey: 'AND', label: i18n._('all.of.the.following.are.true') },
    { formKey: 'OR', label: i18n._('One.of.the.following.is.true') }
  ]

  const findNestedLogic = filters => {
    if (['AND', 'OR'].includes(filters.type)) return true
    if (!filters.operators) return false
    return filters.operators.some(findNestedLogic)
  }
  const hasNestedLogic = visibleFilters.operators.some(findNestedLogic)
  const path = getRealPath(filters)

  return (
    <div>
      {visibleFilters.operators.map((filter, i, children) => (
        <Filter
          key={i}
          i={i}
          level={0}
          path={path}
          childrenLength={children.length}
          modify={modify}
          type={visibleFilters.type}
          filter={filter}
          columnGroups={columnGroups}
          columnsByformKey={columnsByformKey}
          purpose={purpose}
          compareGadgets={compareGadgets}
          topTitle={topTitle}
          hasNestedLogic={hasNestedLogic}
        />
      ))}
      <div className='flex items-center pt-2'>
        <button
          className='kp-button-outline gap-2'
          onClick={() => modify.add(path)}
        >
          <Icons.Add /> <Trans id='add.filter' />
        </button>
        <button
          className='kp-button-transparent'
          onClick={() => modify.clear()}
        >
          <Trans id='clear.filters' />
        </button>
      </div>
    </div>
  )
}

function Filter ({
  i,
  childrenLength,
  level,
  path,
  modify,
  type,
  filter,
  columnGroups,
  columnsByformKey,
  purpose,
  compareGadgets,
  topTitle,
  hasNestedLogic
}) {
  const column = columnsByformKey[filter.field]
  const options =
    gadgets[columnsByformKey[filter.field]?.type]?.filters.options?.(purpose)
  const optionsById = keyBy(options, 'id')
  const option = optionsById[filter.type]
  const isBranch = ['AND', 'OR'].includes(filter.type)
  return (
    <>
      <div className='flex'>
        <div
          className={cx('mb-2 flex flex-1 flex-col justify-center gap-y-2', {
            'pt-2': level !== 0,
            'pt-1': level === 0 && i !== 0
          })}
          style={{ marginLeft: level * 20 }}
        >
          {level === 0 &&
            (i === 0 ? (
              <span className='mb-1 text-sm'>{topTitle}</span>
            ) : i === 1 ? (
              <select
                className='kp-select w-14 border-none bg-transparent p-0'
                value={type}
                onChange={e =>
                  modify.update(path, [{ key: 'type', value: e.target.value }])
                }
              >
                <option value='AND'>
                  <Trans id='AND' />
                </option>
                <option value='OR'>
                  <Trans id='OR' />
                </option>
              </select>
            ) : (
              <span className='mb-1 text-sm lowercase first-letter:uppercase'>
                <Trans id={type} />
              </span>
            ))}
          <div className={cx('flex', { 'ml-9': options })}>
            {!options && (
              <button
                className='kp-button-transparent kp-button-icon mr-0.5 text-medium-gray-500'
                disabled={level > 0 && childrenLength === 1}
                onClick={() => modify.remove(path, i)}
                aria-label={i18n._('delete')}
              >
                <Icons.Remove />
              </button>
            )}
            <select
              className={cx('kp-select', {
                'col-span-2': isBranch,
                'text-medium-gray-500': !filter.field && !isBranch
              })}
              value={isBranch ? filter.type : filter.field || ''}
              onChange={e => {
                const gadgetType =
                  columnsByformKey[e.target.value]?.type || 'NestedLogic'
                if (['AND', 'OR'].includes(e.target.value)) {
                  modify.update(
                    [...path, i],
                    [{ key: 'type', value: e.target.value }]
                  )
                } else {
                  modify.update(
                    [...path, i],
                    [
                      { key: 'field', value: e.target.value },
                      { key: 'gadget', value: gadgetType }
                    ]
                  )
                }
              }}
            >
              {!filter.field && (
                <option value='' disabled className='text-medium-gray-500'>
                  <Trans id='choose.field' />
                </option>
              )}
              {map(columnGroups, (items, title) => (
                <optgroup
                  key={title}
                  label={title}
                  className='text-dark-gray-500'
                >
                  {items.map(item => (
                    <option
                      key={item.formKey}
                      value={item.formKey}
                      className='text-dark-gray-500'
                    >
                      {item.label}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            {isBranch ? (
              <button
                className='kp-button-transparent kp-button-icon ml-0.5 text-green-300'
                onClick={() => modify.add([...path, i])}
                aria-label={i18n._('add')}
                tooltipId='add-filter-id'
              >
                <Tooltip id='add-filter-id'>
                  <div className='w-48'>
                    <Trans id='add.child.below.this.rule' />
                  </div>
                </Tooltip>
                <TooltipTrigger
                  as={Icons.Remove}
                  label={i18n._('add')}
                  tooltipId='add-filter-id'
                  className='rotate-45'
                />
              </button>
            ) : (
              <div className={cx({ 'ml-9': hasNestedLogic })} />
            )}
          </div>
          {options && (
            <div className='flex'>
              <button
                className='kp-button-transparent kp-button-icon mr-0.5 text-medium-gray-500'
                disabled={path.length > 0 && childrenLength === 1}
                onClick={() => modify.remove(path, i)}
                aria-label={i18n._('delete')}
              >
                <Icons.Remove />
              </button>
              <select
                className={cx('kp-select', {
                  'text-medium-gray-500': !filter.type
                })}
                value={filter.type || ''}
                onChange={e => {
                  modify.update(
                    [...path, i],
                    [
                      { key: 'type', value: e.target.value },
                      { key: 'value', value: '' }
                    ]
                  )
                }}
              >
                {!filter.type && (
                  <option value='' disabled className='text-medium-gray-500'>
                    <Trans id='choose.condition' />
                  </option>
                )}
                {options.map(o => (
                  <option
                    key={o.id}
                    value={o.id}
                    className='text-dark-gray-500'
                  >
                    {o.label}
                  </option>
                ))}
              </select>
              <div className={cx({ 'ml-9': hasNestedLogic })} />
            </div>
          )}
          <FilterOperand
            hidden={!options}
            option={option}
            column={column}
            filter={filter}
            updateFull={map => modify.update([...path, i], map)}
            purpose={purpose}
            compareGadgets={compareGadgets}
            hasNestedLogic={hasNestedLogic}
          />
        </div>
      </div>
      {isBranch &&
        filter.operators.map((filter, j, children) => (
          <Filter
            key={j}
            i={j}
            level={level + 1}
            path={[...path, i]}
            childrenLength={children.length}
            modify={modify}
            filter={filter}
            columnGroups={columnGroups}
            columnsByformKey={columnsByformKey}
            purpose={purpose}
            compareGadgets={compareGadgets}
            hasNestedLogic={hasNestedLogic}
          />
        ))}
    </>
  )
}

function FilterOperand ({
  option,
  column,
  updateFull,
  filter,
  purpose,
  compareGadgets,
  hidden,
  hasNestedLogic
}) {
  const update = value => updateFull([{ key: 'value', value }])
  const value = filter.value
  const isCustom = filter.isCustom
  const type = option?.exposes
  let operand = null

  if (type === 'single-select') {
    operand = (
      <select
        className='kp-select'
        value={value}
        onChange={e => update(e.target.value)}
      >
        <option />
        {(option.options || column.details.options).map(o => (
          <option key={o.key || o.id} value={o.key || o.id}>
            {o.lbl || o.label}
          </option>
        ))}
      </select>
    )
  }

  if (type === 'multi-select') {
    operand = (
      <>
        <select
          className='kp-select'
          onChange={e => {
            const statuses = parseVals(value)
            statuses.push(e.target.value)
            return update(`["${statuses.join('","')}"]`)
          }}
          value={value ? 'hasAny' : ''}
        >
          <option value='' disabled>
            - - -
          </option>
          <option value='hasAny' hidden>
            <Trans id='select.statuses' />
          </option>
          {option.options
            .filter(val => !value?.includes(val.id))
            .map(({ id, label }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
        </select>
        {value && (
          <div className='mt-2 flex flex-wrap gap-2'>
            {parseVals(value)?.map(complexVal => (
              <div
                key={`complex-values-${complexVal}-${Math.random()}`}
                className='inline-flex max-w-full items-center overflow-hidden rounded-2xl bg-light-gray-200 pl-4 dark:bg-dark-gray-300 dark:text-white'
              >
                <span className='max-w-[calc(100%-2.5rem)] overflow-hidden text-ellipsis'>
                  {complexVal}
                </span>
                <button
                  className='kp-button kp-button-transparent rounded-l-md rounded-r-2xl'
                  aria-label={i18n._('delete')}
                  onClick={() => {
                    const statuses = parseVals(value).filter(
                      val => val !== complexVal
                    )
                    const val = statuses?.length
                      ? `["${statuses.join('","')}"]`
                      : null
                    update(val)
                  }}
                >
                  <Icons.Close
                    width='8px'
                    height='8px'
                    className='dark:fill-medium-gray-300'
                  />
                </button>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  if (type === 'number') {
    operand = (
      <input
        type='number'
        className='kp-input'
        value={value || 0}
        onChange={e => update(e.target.valueAsNumber)}
      />
    )
  }

  if (type === 'text') {
    operand = (
      <input
        type='text'
        className='kp-input'
        value={value || ''}
        onChange={e => update(e.target.value)}
      />
    )
  }

  // if (type === 'currency') {
  //   operand = (
  //     <input
  //       type='text'
  //       className='kp-input'
  //       onChange={e => {
  //         const val = e.target.value.replace(/[^\d]/g, '')
  //         if (val === '') return update(null)
  //         const number = parseInt(val, 10)
  //         if (!isNaN(number)) return update(number)
  //       }}
  //       value={toDollars(value.value) ?? ''}
  //     />
  //   )
  // }

  if (type === 'date') {
    if (purpose === 'doc-list') {
      operand = (
        <DatePicker
          value={getDateObject(+value)}
          onChange={dateObject =>
            updateFull([
              { key: 'value', value: dateObject.getTime().toString() },
              { key: 'isCustom', value: true }
            ])
          }
        />
      )
      return (
        <div
          className={cx('inline-grid', {
            'mx-9': hasNestedLogic,
            'ml-9': !hasNestedLogic
          })}
        >
          {operand}
        </div>
      )
    } else {
      const thisCompareGads = compareGadgets?.Date || []
      operand = (
        <div className='flex'>
          <select
            className={cx('kp-select', {
              'text-medium-gray-500': !value
            })}
            value={isCustom ? 'custom' : value || ''}
            onChange={e => {
              const val = []
              if (e.target.value === 'custom') {
                val.push({ key: 'isCustom', value: true })
              } else {
                val.push({ key: 'isCustom', value: false })
                val.push({ key: 'value', value: e.target.value })
              }
              updateFull(val)
            }}
          >
            {!value && (
              <option value='' disabled className='text-medium-gray-500'>
                <Trans id='choose.form.field' />
              </option>
            )}
            {thisCompareGads.map(g => (
              <option
                key={g.formKey}
                value={g.formKey}
                className='text-dark-gray-500'
              >
                {g.label}
              </option>
            ))}
            <option key='custom' value='custom'>
              <Trans id='custom' />
            </option>
          </select>
          {isCustom && (
            <DatePicker
              value={getDateObject(+value)}
              onChange={dateObject => update(dateObject.getTime().toString())}
            />
          )}
        </div>
      )
    }
  }

  if (type === 'app-typeahead') {
    if (value) {
      operand = (
        <div className='inline-flex max-w-full items-center gap-2 overflow-hidden rounded-2xl bg-light-gray-200 dark:bg-dark-gray-300'>
          <div
            className='flex h-8 w-8 shrink-0 items-center justify-center rounded-full'
            style={{ background: value.tileOptions.backgroundColor }}
          >
            <AppIcon iconName={value.tileOptions.iconName} className='!w-1/2' />
          </div>
          <span className='truncate'>{value.name}</span>
          <button
            className='kp-button-transparent kp-button-icon rounded-r-2xl'
            aria-label={`remove ${value.name}`}
            onClick={() => update(null)}
          >
            <Icons.Close className='h-2 w-2' />
          </button>
        </div>
      )
    }
    operand = (
      <AppMultiselect value={[]} onChange={values => update(values[0])} />
    )
  }

  if (type === 'integration-typeahead') {
    operand = (
      <IntegrationTypeahead
        details={column.details}
        onChange={val => update(val && pick(val, ['id', 'label']))}
        value={value}
      />
    )
  }

  if (type === 'form-typeahead') {
    operand = (
      <FormTypeahead
        details={column.details}
        onChange={val =>
          update(val && pick(val, ['id', 'documentSetId', 'label']))
        }
        value={value}
      />
    )
  }

  if (type === 'form-multiselect') {
    operand = (
      <FormMultiselect
        details={column.details}
        onChange={update}
        value={value || []}
      />
    )
  }

  if (type === 'group-typeahead') {
    operand = (
      <GroupTypeahead
        details={column.details}
        onChange={val => update(val && pick(val, ['id', 'label']))}
        value={value || ''}
      />
    )
  }

  if (type === 'group-multiselect') {
    operand = (
      <GroupMultiselect
        details={column.details}
        onChange={update}
        value={value || []}
      />
    )
  }

  if (type === 'user-typeahead') {
    operand = (
      <UserTypeahead
        details={column.details}
        onChange={val => update(val && pick(val, ['id', 'label']))}
        value={value || ''}
      />
    )
  }

  if (type === 'user-multiselect') {
    operand = (
      <UserMultiselect
        details={column.details}
        onChange={update}
        value={value || []}
      />
    )
  }

  if (type === 'sharedGadget-terms') {
    const thisCompareGads = compareGadgets?.Terms || []
    operand = (
      <select
        className={cx('kp-select', {
          'text-medium-gray-500': !value
        })}
        value={value || ''}
        onChange={e => update(e.target.value)}
      >
        {!value && (
          <option value='' disabled className='text-medium-gray-500'>
            <Trans id='choose.form.field' />
          </option>
        )}
        {thisCompareGads.map(g => (
          <option
            key={g.formKey}
            value={g.formKey}
            className='text-dark-gray-500'
          >
            {g.label}
          </option>
        ))}
      </select>
    )
  }

  return operand ? (
    <div
      className={cx('text-sm', {
        hidden,
        'mx-9': hasNestedLogic,
        'ml-9': !hasNestedLogic
      })}
    >
      {operand}
    </div>
  ) : null
}

function Empty ({ modify, purpose }) {
  return (
    <div className={cx({ 'p-4': purpose === 'doc-list' })}>
      {purpose === 'doc-list' && (
        <div className='mb-4 whitespace-nowrap border-b border-b-light-gray-300 pb-2'>
          <Trans id='no.filters.applied.to.table' />
        </div>
      )}
      <button
        className='kp-button-outline gap-2'
        onClick={() => modify.create()}
      >
        <Icons.Add /> <Trans id='add.filter' />
      </button>
    </div>
  )
}

function parseVals (str) {
  const val = str ?? ''
  return val
    .replace(/[[\]"]+/g, '')
    .split(',')
    .filter(Boolean)
}

function build (updateParams, outerKey) {
  const getFilter = (f, path) => path.reduce((f, i) => f.operators[i], f)
  return {
    create () {
      updateParams(draft => {
        if (!draft[outerKey]) {
          draft[outerKey] = { type: 'AND', operators: [{}] }
        } else if (draft[outerKey].operators.every(op => op?.isDefault)) {
          draft[outerKey].operators.push({
            type: 'AND',
            operators: [{}]
          })
        }
      })
    },
    add (path) {
      updateParams(draft => {
        const filter = getFilter(draft[outerKey], path)
        filter.operators.push({})
      })
    },
    update (path, keyVals) {
      updateParams(draft => {
        const filter = getFilter(draft[outerKey], path)
        keyVals.forEach(({ key, value }) => {
          filter[key] = value
          if (key === 'field') {
            filter.type = null
            filter.value = null

            // Since branch filters never have a field key, we can clear out the operators
            filter.operators = null
          }
          if (['AND', 'OR'].includes(value) && !filter.operators) {
            filter.operators = [{}]
          }
        })
      })
    },
    remove (path, i) {
      updateParams(draft => {
        const filter = getFilter(draft[outerKey], path)
        filter.operators.splice(i, 1)
        if (draft[outerKey].operators.length === 0) draft[outerKey] = null
      })
    },
    clear () {
      updateParams(draft => {
        draft[outerKey].operators = draft[outerKey]?.operators?.filter(
          o => o?.isDefault
        )
        if (!draft[outerKey].operators?.length) draft[outerKey] = null
      })
    }
  }
}

function cleanFilters (filter) {
  if (!filter) return filter
  if (Object.keys(filter).length === 0) return null
  const newFilter = { ...filter }
  if (filter?.operators?.length) {
    newFilter.operators = filter.operators
      .map(operator => cleanFilters(operator))
      .filter(Boolean)
  }
  const isBranchType = ['AND', 'OR'].includes(filter?.type)
  if (isBranchType && newFilter.operators.length === 0) return null
  if (!isBranchType && !hasCorrectNumberOfParts(newFilter)) return null
  delete newFilter.isCustom

  // Note: deleting `isDefault` is not necessary for the terms overlapping filter
  // because it's transformed first. But if we ever add any other default filters,
  // `isDefault` will need to be deleted here or it will cause backend errrors.

  return newFilter
}

function getVisibleFilters (filters) {
  if (!filters) return filters

  // If there aren't any default filters, we want them as is
  if (!filters.operators.some(op => op?.isDefault)) {
    return filters
  }

  // Pretend that the nested custom filters are the only filters
  return filters.operators.find(op => !op?.isDefault)
}

// If we are hiding default filters and only showing the nested custom
// filters, we need to get the real path to the nested ones so they can
// be updated properly
function getRealPath (filters) {
  if (!filters) return []
  const defaultFilters = filters.operators.filter(op => op?.isDefault)
  return defaultFilters.length ? [defaultFilters.length] : []
}

function hasCorrectNumberOfParts (filter) {
  // If the option you've selected doesn't have an 'exposes', then it only needs a field and a type, no value. IE: "IS_EMPTY" or "IS_TRUE"
  const option = get(gadgets, [filter.gadget, 'filters', 'options'])?.()?.find(
    op => op.id === filter.type
  )
  return filter.type && (filter.value || !option?.exposes)
}

export function insertData (filter, formData) {
  const inserted = doInsertData(filter, formData)
  return cleanFilters(inserted)
}

function doInsertData (filter, formData) {
  if (!filter) return filter
  const newFilter = { ...filter }
  const transformFunc = get(gadgets, [
    filter.gadget,
    'filters',
    'options'
  ])?.()?.find(o => o.id === filter.type)?.transform
  if (transformFunc) return transformFunc(newFilter, formData)

  if (
    typeof filter?.value === 'string' &&
    filter?.value?.includes('KUALI_THIS_FORM_DATA.')
  ) {
    const val = get(formData, newFilter.value.split('KUALI_THIS_FORM_DATA.')[1])
    if (!val) return null
    newFilter.value = `${val}`
  }
  if (filter?.operators?.length) {
    newFilter.operators = filter.operators
      .map(operator => doInsertData(operator, formData))
      .filter(Boolean)
  }

  return newFilter
}
