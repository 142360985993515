/* Copyright © 2024 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { Trans } from '@lingui/react'
import { find, map, reduce, sortBy } from 'lodash'
import React from 'react'

import { getWorkflowSteps } from '../../action/workflow-tracker'

export default function HistoryList ({
  selectedOption,
  documentHistory,
  expandOrCollapseAll,
  notifications,
  showFutureSteps,
  updateWorkflowOverview,
  workflowOverview
}) {
  const {
    appId,
    documentId,
    simulation,
    hasWFTroubleshootPerms,
    workflowResends,
    ...props
  } = documentHistory
  const hasSimulationSteps = (simulation?.steps || []).length > 0
  switch (selectedOption) {
    case 'all': {
      const mergedHistory = mergeHistory({
        workflowSteps:
          workflowOverview.stepsWithAllVersions || workflowOverview.steps,
        notifications: adaptNotifications(notifications)
      })
      return hasSimulationSteps ? (
        <div>
          {getWorkflowSteps(
            { steps: mergedHistory },
            {
              appId,
              documentId,
              expandOrCollapseAll,
              hasWFTroubleshootPerms,
              showFutureSteps,
              updateWorkflowOverview,
              workflowResends,
              ...props
            }
          )}
        </div>
      ) : (
        <div>
          <Trans
            id='no.workflow.history'
            message='There is no workflow history yet for this document.'
          />
        </div>
      )
    }
    case 'edits':
      return <div>Coming soon!</div>
    case 'workflow': {
      const mergedHistory = mergeHistory({
        workflowSteps:
          workflowOverview.stepsWithAllVersions || workflowOverview.steps,
        notifications: adaptNotifications(notifications),
        workflowOnly: true
      })
      return hasSimulationSteps ? (
        <div>
          {getWorkflowSteps(
            { steps: mergedHistory },
            {
              appId,
              documentId,
              expandOrCollapseAll,
              hasWFTroubleshootPerms,
              showFutureSteps,
              updateWorkflowOverview,
              workflowResends,
              ...props
            }
          )}
        </div>
      ) : (
        <div>
          <Trans
            id='no.workflow.history'
            message='There is no workflow history yet for this document.'
          />
        </div>
      )
    }
    case 'email':
      return (
        <div>
          {getWorkflowSteps(
            { steps: adaptNotifications(notifications) },
            {
              appId,
              documentId,
              expandOrCollapseAll,
              hasWFTroubleshootPerms,
              showFutureSteps,
              updateWorkflowOverview,
              workflowResends,
              ...props
            }
          )}
        </div>
      )
    default:
      return <div>Nothing to see here!</div>
  }
}

function mergeHistory ({
  workflowSteps = [],
  notifications = [],
  workflowOnly = false
}) {
  const nonWFNotifications = reduce(
    notifications,
    (accum, notification) => {
      if (notification.workflowStepId && !notification.isReminder) {
        const wfStep = find(workflowSteps, {
          id: notification.workflowStepId
        })
        if (wfStep) {
          wfStep.notificationInfo = notification
          return accum
        }
      }
      accum.push(notification)
      return accum
    },
    []
  )
  const sorted = sortBy(
    workflowOnly ? workflowSteps : [...workflowSteps, ...nonWFNotifications],
    item => item.completedAt || (!item.simulated ? item.startedAt : null)
  )
  return sorted
}

function adaptNotifications (notifications = []) {
  return map(notifications, notification => {
    return {
      id: notification.id,
      definitionId: 'notification',
      completedAt: ensureISOFormat(notification.date),
      type: 'notification-history',
      subject: notification.content?.subject,
      htmlBody: notification.content?.bodyHtml,
      body: notification.content?.bodyText,
      recipients: notification.content?.recipients,
      versionNumber: notification.versionNumber,
      msg: notification.content?.isReminder
        ? 'reminder sent'
        : 'notification sent',
      isReminder: notification.content?.isReminder,
      workflowStepId: notification.content?.workflowStepId
    }
  })
}

function ensureISOFormat (dateString) {
  return dateString.replace(' ', 'T')
}
